import { z } from "zod";
import ValidatableForm, { useForm } from "../common/ValidatableForm";
import ValidatableFormField from "../common/ValidatableFormField";

const formSchema = z.object({
  name: z
    .string()
    .min(1, "Please enter a name")
    .max(25, "There are max. 25 characters allowed"),
  email: z.string().email("Please enter a valid email address."),
});

export type CustomerInfo = z.infer<typeof formSchema>;

interface CustomerInfoFormProps {
  hidden: boolean;
  onFormSubmit: (customerInfo: CustomerInfo) => void;
}

const CustomerInfoForm = (props: CustomerInfoFormProps) => {
  const form = useForm({
    schema: formSchema,
  });

  return (
    <div>
      {!props.hidden && (
        <ValidatableForm
          form={form}
          onSubmit={(values) => props.onFormSubmit(values)}
        >
          <ValidatableFormField
            label="Name"
            type="text"
            placeholder=""
            additionalClasses="max-w-[300px]"
            {...form.register("name")}
          />

          <ValidatableFormField
            label="E-Mail"
            type="email"
            placeholder=""
            additionalClasses="max-w-[300px]"
            {...form.register("email")}
          />

          <ValidatableFormField
            label="Zur Kasse"
            type="submit"
            additionalClasses="mr-auto"
          />
        </ValidatableForm>
      )}
    </div>
  );
};

export default CustomerInfoForm;
