import _ from "lodash";
import { Event } from "@code-on-the-rocks/ticket-flamingo-common";
import EventListItem from "./EventListItem";

interface EventListGroupProps {
  events: Event[];
  onDeleteEvent: (id: string) => void;
  onDuplicateEvent: (id: string) => void;
}

const EventListGroup = (props: EventListGroupProps) => {
  const { dayGroups, dayGroupsArray } = prepareDayGroups(props.events);

  return (
    <div>
      {dayGroupsArray.map((day) => (
        <div key={day} className="mt-[40px] max-lg:mt-6">
          <span className="mb-5 inline-block text-[22px] font-medium text-primaryDark max-lg:mb-2 max-lg:text-[20px]">
            {day}
          </span>
          {dayGroups[day].map((event) => (
            <EventListItem
              key={event.id}
              event={event}
              onDeleteEvent={props.onDeleteEvent}
              onDuplicateEvent={props.onDuplicateEvent}
            />
          ))}
        </div>
      ))}
    </div>
  );
};

function prepareDayGroups(events: Event[]) {
  const sortedEvents = _.sortBy(events, (a) => a.start.milliseconds);

  const dayGroups = _.groupBy(sortedEvents, (event) =>
    new Date(event.start.milliseconds).toLocaleDateString()
  );

  const dayGroupsArray = [];

  for (const day in dayGroups) {
    dayGroups[day].sort((a, b) => a.start.milliseconds - b.start.milliseconds);
    dayGroupsArray.push(day);
  }

  return { dayGroups, dayGroupsArray };
}

export default EventListGroup;
