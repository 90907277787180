import { useParams } from "react-router-dom";
import EventHeader from "../common/EventHeader";

const NotFoundPage = () => {
  const { clientName } = useParams();
  const showNavigateBack = clientName ? true : false;

  return (
    <div>
      <EventHeader showNavigateBack={showNavigateBack} to={`/${clientName}`} />
      <main>
        <h1 className="mx-5 my-10 text-[28px]">
          Oops da ist was schief gelaufen
        </h1>
      </main>
    </div>
  );
};

export default NotFoundPage;
