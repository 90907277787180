import { UserWithoutPassword } from "@code-on-the-rocks/ticket-flamingo-common";
import { useEffect, useState } from "react";
import { Navigate, useLocation, useParams } from "react-router-dom";
import authService from "../../services/authService";
import { useHandleInvalidSession } from "../hooks/useHandlenvalidSession";

export type ProtectedRouteProps = {
  user: UserWithoutPassword | null;
  authenticationPath: string;
  redirectPath: string;
  setRedirectPath: (path: string) => void;
  outlet: JSX.Element;
};

export default function ProtectedRoute({
  user,
  authenticationPath,
  redirectPath,
  setRedirectPath,
  outlet,
}: ProtectedRouteProps) {
  const currentLocation = useLocation();
  const { clientName } = useParams();
  const invalidateSession = useHandleInvalidSession(clientName!);
  const [shouldRedirect, setShouldRedirect] = useState(false);

  useEffect(() => {
    if (authService.isRefreshTokenExpired()) {
      invalidateSession();
      setShouldRedirect(true);
    }

    if (!user) {
      setShouldRedirect(true);
      setRedirectPath(currentLocation.pathname);
    }
  }, [user, setRedirectPath, currentLocation, invalidateSession]);

  if (
    shouldRedirect ||
    (redirectPath && redirectPath !== currentLocation.pathname)
  )
    return (
      <Navigate
        replace
        to={{
          pathname: user ? redirectPath : `/${clientName}${authenticationPath}`,
        }}
      />
    );

  return outlet;
}
