import { UserWithoutPassword } from "@code-on-the-rocks/ticket-flamingo-common";
import http from "../services/httpService";

export const apiEndpoint = process.env.REACT_APP_API_URL + "users";

export async function updateUser(
  userId: string,
  updateFields: {
    firstname?: string;
    lastname?: string;
    email?: string;
  }
): Promise<[number, UserWithoutPassword]> {
  const response = await http.put<UserWithoutPassword>(
    `${apiEndpoint}/${userId}`,
    updateFields
  );
  return [response.status, response.data];
}

export async function updateUserPassword(
  userId: string,
  oldPassword: string,
  newPassword: string
) {
  const payload = {
    oldPassword,
    newPassword,
  };
  const response = await http.put<UserWithoutPassword>(
    `${apiEndpoint}/${userId}`,
    payload
  );
  return [response.status, response.data];
}
