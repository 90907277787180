import { z } from "zod";
import ValidatableForm, { useForm } from "../common/ValidatableForm";
import ValidatableFormField from "../common/ValidatableFormField";
import { UserWithoutPassword } from "@code-on-the-rocks/ticket-flamingo-common";
import { updateUser } from "../../api/userApi";
import { toast } from "react-toastify";
import { UserUpdatePayload } from "@code-on-the-rocks/ticket-flamingo-common/dist/user/User";
import _ from "lodash";

interface UserFormProps {
  user: UserWithoutPassword;
  onSuccessfulUserUpdate: (user: UserWithoutPassword) => void;
  onLogout: () => void;
}

const UserForm = ({
  user,
  onSuccessfulUserUpdate,
  onLogout,
}: UserFormProps) => {
  const userForm = useForm({
    schema: userFormSchema,
  });

  const handleSubmit = async (formValues: z.infer<typeof userFormSchema>) => {
    try {
      const originalUserPayload: UserUpdatePayload = {
        firstname: user.firstname,
        lastname: user.lastname,
        email: user.email,
      };

      const userUpdatePayload: UserUpdatePayload = {
        firstname: formValues.firstname,
        lastname: formValues.lastname,
        email: formValues.email,
      };

      if (!_.isEqual(originalUserPayload, userUpdatePayload)) {
        const [resStatus, resData] = await updateUser(
          user.id,
          userUpdatePayload
        );
        if (resStatus !== 200) throw new Error("non 200 response");
        toast.success("User updated!");
        onSuccessfulUserUpdate(resData);
      }
    } catch (error) {
      toast.error("User not updated");
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-[36px] font-light max-sm:text-2xl">
        Nutzerprofil
      </h1>

      <ValidatableForm form={userForm} onSubmit={handleSubmit}>
        <ValidatableFormField
          label="Vorname"
          type="text"
          placeholder=""
          additionalClasses="w-[300px]"
          defaultValue={user.firstname}
          {...userForm.register("firstname")}
        />

        <ValidatableFormField
          label="Nachname"
          type="text"
          placeholder=""
          additionalClasses="w-[300px]"
          defaultValue={user.lastname}
          {...userForm.register("lastname")}
        />

        <ValidatableFormField
          label="E-Mail"
          type="email"
          placeholder=""
          additionalClasses="w-[300px]"
          defaultValue={user.email}
          {...userForm.register("email")}
        />

        {/* <ValidatableFormField
          label="Passwort"
          type="password"
          placeholder=""
          additionalClasses="w-[300px]"
          {...userForm.register("password")}
        /> */}

        <ValidatableFormField
          label="Update Nutzerprofil"
          type="submit"
          additionalClasses="mr-auto"
        />
      </ValidatableForm>

      <button
        onClick={onLogout}
        className={"btn-primary mt-1 mb-2 bg-secondary hover:bg-secondaryDark"}
      >
        Logout
      </button>
    </div>
  );
};

export const userFormSchema = z.object({
  firstname: z.string().min(1, "Bitte gib deinen Vornamen ein."),
  lastname: z.string().min(1, "Bitte gib deinen Nachnamen ein."),
  email: z.string().email("Bitte gib eine gütlige Email Adresse ein."),
  //   passwordNew: z.string().min(10, "B")
  //   passwordNewRepeat: z.string().min(10)
  //   passwordOld: z.string().min(1, "Bitte gib dein Passwort ein."),
});

export default UserForm;
