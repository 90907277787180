const Footer = () => {
  return (
    <footer className="relative bottom-0 mt-20 mb-4 text-center text-[12px]">
      <div className="h-full"></div>
      Made with ❤️ in Bavaria by TicketFlamingo
    </footer>
  );
};

export default Footer;
