import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClone } from "@fortawesome/free-regular-svg-icons";
import {
  faChevronRight,
  faPenToSquare,
  faUserGroup,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import { useSessionContext } from "../context/SessionContext";

interface EventListItemButtonProps {
  eventTitle: string;
  eventId: string;
  bookedOut: boolean;
  onDeleteEvent: (id: string) => void;
  onDuplicateEvent: (id: string) => void;
}

const EventListItemButtons = (props: EventListItemButtonProps) => {
  const [sessionContext] = useSessionContext();
  const { clientName } = useParams();

  const buttonColor = props.bookedOut ? "text-black/50" : "text-primaryDark";

  return (
    <div className={"flex items-center gap-4 pr-5 " + buttonColor}>
      {sessionContext.user && (
        <>
          <button
            onClick={() => props.onDeleteEvent(props.eventId)}
            className="group flex hover:text-white hover:opacity-100"
            aria-label={`Delete event with name ${props.eventTitle}`}
          >
            <FontAwesomeIcon icon={faX} size="lg" />
          </button>

          <button
            onClick={() => props.onDuplicateEvent(props.eventId)}
            className="group flex hover:text-white hover:opacity-100"
            aria-label={`Duplicate event with name ${props.eventTitle}`}
          >
            <FontAwesomeIcon icon={faClone} size="lg" />
          </button>

          <Link
            to={`/${clientName}/events/edit/${props.eventId}`}
            className="group flex hover:text-white hover:opacity-100"
            aria-label={`Edit event with name ${props.eventTitle}`}
          >
            <FontAwesomeIcon icon={faPenToSquare} size="lg" />
          </Link>
          <Link
            to={`/${clientName}/orders/${props.eventId}`}
            className="group flex hover:text-white hover:opacity-100"
            aria-label={`Show orders for event with name ${props.eventTitle}`}
          >
            <FontAwesomeIcon icon={faUserGroup} size="lg" />
          </Link>
        </>
      )}

      <Link
        to={`/${clientName}/events/${props.eventId}`}
        className="group flex hover:text-white hover:opacity-100"
        aria-label={`Show event with name ${props.eventTitle}`}
      >
        {!sessionContext.user && <span className="max-lg:hidden">mehr</span>}
        <FontAwesomeIcon icon={faChevronRight} size="lg" />
      </Link>
    </div>
  );
};

export default EventListItemButtons;
