import "./index.css";
import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { verifyEnvironment } from "./utils/helper-funcs";
import { SessionContextProvider } from "./components/context/SessionContext";
import Footer from "./components/common/Footer";

verifyEnvironment(["REACT_APP_API_URL", "REACT_APP_IMAGE_URL"]);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <ToastContainer />

      <SessionContextProvider>
        <div className="mx-4 my-4">
          <App />
        </div>
        <Footer />
      </SessionContextProvider>
    </BrowserRouter>
  </React.StrictMode>
);
