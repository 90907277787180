import { Route } from "react-router-dom";
import { Routes } from "react-router";
import ProtectedRoute, {
  ProtectedRouteProps,
} from "./components/common/ProtectedRoute";
import { useSessionContext } from "./components/context/SessionContext";
import EventListPage from "./components/pages/EventListPage";
import EventCustomerPage from "./components/pages/EventCustomerPage";
import EventEditPage from "./components/pages/EventEditPage";
import CheckOutPage, {
  initialPaypalButtonOptions,
} from "./components/pages/CheckOutPage";
import CheckOutContext from "./components/context/CheckOutContext";
import NotFoundPage from "./components/pages/NotFoundPage";
import EventOrdersPage from "./components/pages/EventOrdersPage";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import useDocumentHeightReporter from "./components/hooks/useDocumentHeightReporter";
import ProfilePage from "./components/pages/ProfilePage";

function App() {
  const defaultProtectedRouteProps = useSessionLogic();

  useDocumentHeightReporter();

  return (
    <CheckOutContext>
      <Routes>
        {/* TODO: Ein Client sollte dann auch nicht not-found heißen :D */}
        <Route path="/not-found" element={<NotFoundPage />} />
        <Route path="/:clientName/profile" element={<ProfilePage />} />
        <Route path="/:clientName/" element={<EventListPage />} />
        <Route
          path="/:clientName/checkout/"
          element={
            <PayPalScriptProvider
              options={{ clientId: "", ...initialPaypalButtonOptions }}
            >
              <CheckOutPage />
            </PayPalScriptProvider>
          }
        />

        <Route
          path="/:clientName/events/:eventId"
          element={<EventCustomerPage />}
        />

        <Route
          path="/:clientName/events/new"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<EventEditPage />}
            />
          }
        />

        <Route
          path="/:clientName/events/new-from-template"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<EventEditPage />}
            />
          }
        />
        <Route
          path="/:clientName/events/new-template"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<EventEditPage />}
            />
          }
        />
        <Route
          path="/:clientName/events/edit/:eventId"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<EventEditPage />}
            />
          }
        />
        <Route
          path="/:clientName/orders/:eventId"
          element={
            <ProtectedRoute
              {...defaultProtectedRouteProps}
              outlet={<EventOrdersPage />}
            />
          }
        />

        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </CheckOutContext>
  );
}

function useSessionLogic() {
  const [sessionContext, updateSessionContext] = useSessionContext();

  const setRedirectPath = (path: string) => {
    updateSessionContext({ ...sessionContext, redirectPath: path });
  };

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    user: sessionContext.user,
    authenticationPath: "/profile",
    redirectPath: sessionContext.redirectPath,
    setRedirectPath: setRedirectPath,
  };

  return defaultProtectedRouteProps;
}

export default App;
