import { PublicClient } from "@code-on-the-rocks/ticket-flamingo-common";
import { AbstractCache } from "./abstract-cache";

export class PublicClientCache extends AbstractCache<PublicClient> {
  private static instance: PublicClientCache;

  private constructor(validityDurationMs: number) {
    super(validityDurationMs);
  }

  public static getInstance(
    validityDurationMs: number = 600000
  ): PublicClientCache {
    if (!PublicClientCache.instance) {
      PublicClientCache.instance = new PublicClientCache(validityDurationMs);
    }
    return PublicClientCache.instance;
  }
}
