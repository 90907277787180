import { useNavigate } from "react-router-dom";

interface NavigateBackProps {
  label: string;
  to: string | number;
}

const NavigateBack = (props: NavigateBackProps) => {
  const { label, to } = props;
  const navigate = useNavigate();

  const handleNavigation = () => {
    if (typeof to === "number") navigate(to);
    else if (typeof to === "string") navigate(to);
  };

  return (
    <button
      className="my-5 inline-block text-[16px] font-medium text-primaryDark hover:text-primary"
      onClick={handleNavigation}
    >{`<${label}`}</button>
  );
};

export default NavigateBack;
