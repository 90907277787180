import { zodResolver } from "@hookform/resolvers/zod";
import { ComponentProps } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useFormContext,
  UseFormReturn,
  useForm as useHookForm,
  UseFormProps as UseHookFormProps,
} from "react-hook-form";
import { TypeOf, ZodSchema } from "zod";
import ErrorLabel from "./ErrorLabel";

interface ValidatableFormProps<T extends FieldValues = any>
  extends Omit<ComponentProps<"form">, "onSubmit"> {
  form: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
}

interface UseFormProps<T extends ZodSchema<any>>
  extends UseHookFormProps<TypeOf<T>> {
  schema: T;
}

export const useForm = <T extends ZodSchema<any>>({
  schema,
  ...formConfig
}: UseFormProps<T>) => {
  return useHookForm({
    ...formConfig,
    resolver: zodResolver(schema),
  });
};

export const ValidatableForm = <T extends FieldValues>({
  form,
  onSubmit,
  children,
  ...props
}: ValidatableFormProps<T>) => {
  return (
    <FormProvider {...form}>
      {/* the `form` passed here is return value of useForm() hook */}
      <form onSubmit={form.handleSubmit(onSubmit)} {...props}>
        <fieldset disabled={form.formState.isSubmitting}>{children}</fieldset>
      </form>
    </FormProvider>
  );
};

export function FieldError({ name }: { name?: string }) {
  // the useFormContext hook returns the current state of hook form.
  const {
    formState: { errors },
  } = useFormContext();

  if (!name) return null;

  const error = errors[name];

  if (!error) return null;

  return <ErrorLabel text={error.message!.toString()} />;
}

export default ValidatableForm;
