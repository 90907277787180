import { DateTime } from "@code-on-the-rocks/ts-datetime";
import http from "../services/httpService";
import {
  Event,
  EventImplementation,
  EventOptionWithAmount,
  EventOrder,
  EventWithoutId,
} from "@code-on-the-rocks/ticket-flamingo-common";
import { CustomerInfo } from "../components/event-ticket/CustomerInfoForm";

const apiEndpoint = process.env.REACT_APP_API_URL + "events";

export async function getEvents(clientId: string) {
  try {
    const response = await http.get<Event[]>(apiEndpoint + "/" + clientId);

    return response.data.map(
      (eventData) =>
        new EventImplementation(
          eventData.clientId,
          eventData.id,
          eventData.title,
          eventData.descriptionShort,
          eventData.descriptionLong,
          eventData.location,
          new DateTime(eventData.start.milliseconds),
          new DateTime(eventData.end.milliseconds),
          eventData.slots,
          eventData.slotsSold,
          eventData.image,
          eventData.options,
          eventData.orders
        )
    );
  } catch (error: any) {
    http.handleError(error);
    return [];
  }
}

export async function getEvent(clientId: string, id: string) {
  try {
    const response = await http.get<Event>(`${apiEndpoint}/${clientId}/${id}`);

    return new EventImplementation(
      response.data.clientId,
      response.data.id,
      response.data.title,
      response.data.descriptionShort,
      response.data.descriptionLong,
      response.data.location,
      new DateTime(response.data.start.milliseconds),
      new DateTime(response.data.end.milliseconds),
      response.data.slots,
      response.data.slotsSold,
      response.data.image,
      response.data.options,
      response.data.orders
    );
  } catch (error: any) {
    http.handleError(error);
    return null;
  }
}

export async function getEventWithOrders(clientId: string, id: string) {
  const response = await http.get<Event>(
    `${apiEndpoint}/with-orders/${clientId}/${id}`
  );

  return new EventImplementation(
    response.data.clientId,
    response.data.id,
    response.data.title,
    response.data.descriptionShort,
    response.data.descriptionLong,
    response.data.location,
    new DateTime(response.data.start.milliseconds),
    new DateTime(response.data.end.milliseconds),
    response.data.slots,
    response.data.slotsSold,
    response.data.image,
    response.data.options,
    response.data.orders
  );
}

export async function saveEvent(event: EventWithoutId) {
  const response = await http.post<Event>(apiEndpoint, event);
  return new EventImplementation(
    response.data.clientId,
    response.data.id,
    response.data.title,
    response.data.descriptionShort,
    response.data.descriptionLong,
    response.data.location,
    new DateTime(response.data.start.milliseconds),
    new DateTime(response.data.end.milliseconds),
    response.data.slots,
    response.data.slotsSold,
    response.data.image,
    response.data.options,
    response.data.orders
  );
}

export function updateEvent(
  clientId: string,
  id: string,
  event: EventWithoutId
) {
  return http.put<Event>(`${apiEndpoint}/${clientId}/${id}`, event);
}

export function removeEvent(clientId: string, id: string) {
  return http.delete<Event>(`${apiEndpoint}/${clientId}/${id}`);
}

export function confirmOrder(
  dryRun: boolean,
  eventId: string,
  paypalOrderId: string,
  options: EventOptionWithAmount[],
  customer: CustomerInfo
) {
  return http.post<EventOrder>(apiEndpoint + "/confirm", {
    dryRun,
    eventId,
    paypalOrderId,
    options,
    customer,
  });
}
