import { z } from "zod";
import ValidatableForm, { useForm } from "../common/ValidatableForm";
import ValidatableFormField from "../common/ValidatableFormField";
import { UserLoginAttemptImplementation } from "@code-on-the-rocks/ticket-flamingo-common";
import auth from "../../services/authService";
import { toast } from "react-toastify";

interface LoginFormProps {
  clientId: string;
  onSuccessfulLogin: () => void;
}

const LoginForm = ({ clientId, onSuccessfulLogin }: LoginFormProps) => {
  const form = useForm({
    schema: loginFormSchema,
  });

  const handleLogin = async (loginData: z.infer<typeof loginFormSchema>) => {
    const loginAttempt = new UserLoginAttemptImplementation(
      clientId,
      loginData.email,
      loginData.password
    );
    const status = await auth.login(loginAttempt);

    if (status === 200) {
      toast.success("Logged in");
      onSuccessfulLogin();
    } else {
      if (status === 401 || status === 404)
        form.setError("password", { message: "Invalid email or password" });
      else {
        form.reset();
        toast.error("Unexpected Error");
      }
    }
  };

  return (
    <div>
      <h1 className="mb-4 text-[36px] font-light">Login</h1>

      <ValidatableForm form={form} onSubmit={handleLogin}>
        <ValidatableFormField
          label="E-Mail"
          type="email"
          placeholder=""
          additionalClasses="max-w-[300px]"
          {...form.register("email")}
        />

        <ValidatableFormField
          label="Passwort"
          type="password"
          placeholder=""
          additionalClasses="max-w-[300px]"
          {...form.register("password")}
        />

        <ValidatableFormField
          label="Login"
          type="submit"
          additionalClasses="mr-auto"
        />
      </ValidatableForm>
    </div>
  );
};

const loginFormSchema = z.object({
  email: z.string().email("Bitte gib eine gütlige Email Adresse ein."),
  password: z.string().min(1, "Bitte gib dein Passwort ein."),
});

export default LoginForm;
