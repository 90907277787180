import { DateTime } from "@code-on-the-rocks/ts-datetime";

interface IEventIntroProps {
  title: string;
  location: string;
  startDate: DateTime;
  endDate: DateTime;
}

const EventIntro = (props: IEventIntroProps) => {
  const dateText = `${new Date(
    props.startDate.milliseconds
  ).toLocaleDateString()} @ ${props.startDate.toHumanReadableTimeString()} - ${props.endDate.toHumanReadableTimeString()}`;

  return (
    <div>
      <h1 className="text-[36px] font-light max-lg:text-[28px] max-sm:text-[22px]">
        {props.title}
      </h1>
      <div className="mt-2 flex flex-col text-[20px] font-normal text-black/70 max-lg:text-[18px] max-sm:text-[16px]">
        <span>{dateText}</span>
        <span>{props.location}</span>
      </div>
    </div>
  );
};

export default EventIntro;
