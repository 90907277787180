import { Event } from "@code-on-the-rocks/ticket-flamingo-common";
import EventOrdersTableRow, {
  IEventOrdersTableRow,
} from "./EventOrdersTableRow";

interface IEventOrdersTableProps {
  event: Event;
  deleteTicket: (orderId: string, optionId: string) => void;
  updateTicketAmount: (
    orderId: string,
    optionId: string,
    amount: number
  ) => void;
}

const EventOrdersTable = (props: IEventOrdersTableProps) => {
  const tableRows: IEventOrdersTableRow[] = [];

  for (const order of props.event.orders) {
    for (const option of order.options) {
      tableRows.push({
        customerEmail: order.customerEmail,
        customerName: order.customerName,
        paypalOrderId: order.paypalOrderId,
        orderDate: order.orderDate,
        option: option,
        mailSent: order.mailSent,
        decreaseTicketAmount: () => {
          props.updateTicketAmount(order.id, option.id, --option.amount);
        },
        deleteTicket: () => {
          props.deleteTicket(order.id, option.id);
        },
      });
    }
  }

  return (
    <div>
      <table className="table w-full">
        <thead className="table-header-group py-6">
          <tr className="table-row border-b-[3px] border-primaryDark">
            <td className="hidden px-2 text-left max-lg:table-cell">
              Datum &<br />
              PayPal OrderID
            </td>
            <td className="table-cell px-2 text-left max-lg:hidden">Datum</td>
            <td className="table-cell px-2 text-left">Teilnehmer</td>
            <td className="table-cell px-2 text-left max-lg:hidden">
              Paypal OrderID
            </td>
            <td className="table-cell px-2 text-left">Ticket</td>
            <td className="table-cell px-2 text-left max-lg:hidden">Anzahl</td>
            <td className="table-cell px-2 text-left max-lg:hidden">Preis</td>
            <td className="table-cell px-2 text-left max-lg:hidden">
              Ticket
              <br />
              versendet
            </td>
            <td className="table-cell px-2 text-left"></td>
          </tr>
        </thead>

        <tbody className="table-row-group">
          {tableRows.map((row, index) => (
            <EventOrdersTableRow
              row={row}
              index={index}
              key={row.paypalOrderId + row.option.id}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventOrdersTable;
