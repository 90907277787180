import { useState, useEffect } from "react";
import { Event } from "@code-on-the-rocks/ticket-flamingo-common";
import EventHeader from "../common/EventHeader";
import EventListGroup from "../event/EventListGroup";
import { getEvents, removeEvent, saveEvent } from "../../api/eventApi";
import { toast } from "react-toastify";
import _ from "lodash";
import { EventWithoutIdImplementation } from "@code-on-the-rocks/ticket-flamingo-common";
import { useHandleInvalidSession } from "../hooks/useHandlenvalidSession";
import { DateTime } from "@code-on-the-rocks/ts-datetime";
import { Session, useSessionContext } from "../context/SessionContext";
import EventFilterSelector, {
  EVENT_FILTER,
} from "../event/EventFilterSelector";
import { useSetupClientOrRedirect } from "../hooks/useSetupClientOrRedirect";

const EventListPage = () => {
  const [
    clientName,
    showError,
    events,
    sessionContext,
    eventFilter,
    setEventFilter,
    handleDeleteEvent,
    handleDuplicateEvent,
  ] = useEventListPageLogic();

  return (
    <div>
      <EventHeader to={`/${clientName}`} showNavigateBack={false} />
      <main>
        {sessionContext.user && (
          <EventFilterSelector
            activeEventFilter={eventFilter}
            setEventFilter={setEventFilter}
          />
        )}

        {events.length > 0 && (
          <EventListGroup
            events={events}
            onDeleteEvent={handleDeleteEvent}
            onDuplicateEvent={handleDuplicateEvent}
          />
        )}

        {showError && (
          <div className="m-5 text-lg">There is no event right now.</div>
        )}
      </main>
    </div>
  );
};

function useEventListPageLogic(): [
  string,
  boolean,
  Event[],
  Session,
  EVENT_FILTER,
  (viewMode: EVENT_FILTER) => void,
  (id: string) => void,
  (id: string) => void
] {
  const [clientId, clientName] = useSetupClientOrRedirect();
  const invalidateSession = useHandleInvalidSession(clientName!);
  const [sessionContext] = useSessionContext();

  const [eventFilter, seteventFilter] = useState<EVENT_FILTER>(
    EVENT_FILTER.UPCOMING
  );
  const [events, setEvents] = useState<Event[]>([]);
  const [showError, setShowError] = useState(false);

  let retEvents: Event[] = [];

  // TODO: ADMIN Modus
  if (eventFilter === EVENT_FILTER.UPCOMING)
    retEvents = events.filter((event) =>
      event.start.greaterEqualsThan(DateTime.now())
    );
  else if (eventFilter === EVENT_FILTER.PAST)
    retEvents = events.filter((event) => event.start.lessThan(DateTime.now()));
  else if (eventFilter === EVENT_FILTER.DELETED) {
    throw new Error("not implemented");
  }

  useEffect(() => {
    const fetchData = async () => {
      if (clientId) {
        const response = await getEvents(clientId);
        const responseEvents = response;
        setEvents(responseEvents);
        if (responseEvents.length === 0) setShowError(true);
      }
    };

    fetchData();
  }, [clientId]);

  const handleDeleteEvent = async (id: string) => {
    const originalEvents = _.clone(events);
    const newEvents = events.filter((event) => event.id !== id);
    setEvents(newEvents);

    try {
      await removeEvent(clientId, id);
      toast.success("Event removed");
    } catch (error: any) {
      if (error.response && error.response.status === 404)
        toast.error("Event has already been deleted");
      else if (error.response && error.response.status === 401) {
        invalidateSession();
      } else {
        toast.error("Something went wrong.");
      }

      setEvents(originalEvents);
    }
  };

  const handleDuplicateEvent = async (id: string) => {
    const eventToDuplicate = events.find((event) => event.id === id);

    if (eventToDuplicate) {
      const originalEvents = _.clone(events);
      const newEvent = new EventWithoutIdImplementation(
        eventToDuplicate.clientId,
        eventToDuplicate.title + " - Neu",
        eventToDuplicate.descriptionShort,
        eventToDuplicate.descriptionLong,
        eventToDuplicate.location,
        eventToDuplicate.start,
        eventToDuplicate.end,
        eventToDuplicate.slots,
        0,
        eventToDuplicate.image,
        eventToDuplicate.options,
        []
      );

      try {
        const duplicateEventResponse = await saveEvent(newEvent);
        const newEvents = [...originalEvents, duplicateEventResponse];

        setEvents(newEvents);
        toast.success("Event duplicated");
      } catch (error: any) {
        if (error.response && error.response.status === 400)
          toast.error("Invalid data has been passed to server.");
        else if (error.response && error.response.status === 401) {
          invalidateSession();
        } else toast.error("Something went wrong.");

        setEvents(originalEvents);
      }
    }
  };

  return [
    clientName!,
    showError,
    retEvents,
    sessionContext,
    eventFilter,
    seteventFilter,
    handleDeleteEvent,
    handleDuplicateEvent,
  ];
}

export default EventListPage;
