import { EventOptionWithAmount } from "@code-on-the-rocks/ticket-flamingo-common";
import { DateTime } from "@code-on-the-rocks/ts-datetime";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface IEventOrdersTableRow {
  orderDate: DateTime;
  customerName: string;
  customerEmail: string;
  paypalOrderId: string;
  option: EventOptionWithAmount;
  mailSent: boolean;
  deleteTicket: () => void;
  decreaseTicketAmount: () => void;
}

interface IEventOrdersTableRowProps {
  row: IEventOrdersTableRow;
  index: number;
}

const EventOrdersTableRow = (props: IEventOrdersTableRowProps) => {
  const rowBackground = props.index % 2 ? " bg-primary" : " bg-primary60";

  const orderDate = DateTime.FromMilliseconds(props.row.orderDate.milliseconds);

  return (
    <tr className={"mb-2 table-row" + rowBackground}>
      <td className="mb-2 table-cell p-2 text-left">
        <div className="flex flex-col">
          <span>{orderDate.toHumanReadableDateString()}</span>
          <span className="text hidden break-all max-lg:block">
            {props.row.paypalOrderId}
          </span>
        </div>
      </td>
      <td className={"mb-2 table-cell p-2 text-left"}>
        <div className="flex flex-col break-all">
          <span className="max-lg:font-medium">{props.row.customerName}</span>
          <span>{props.row.customerEmail}</span>
        </div>
      </td>
      <td className="mb-2 table-cell p-2 text-left max-lg:hidden">
        {props.row.paypalOrderId}
      </td>
      <td className="mb-2 table-cell p-2 text-left">
        <div className="flex flex-col">
          <span className="max-lg:font-medium">{props.row.option.name}</span>
          <span className="hidden max-lg:block">
            Anzahl: {props.row.option.amount}
          </span>
          <span className="hidden max-lg:block">
            Preis: {props.row.option.amount * props.row.option.price + " €"}
          </span>
          <span className="hidden max-lg:block">
            Gesendet: {props.row.mailSent ? "Ja" : "Nein"}
          </span>
        </div>
      </td>
      <td className="mb-2 table-cell p-2 text-left max-lg:hidden">
        {props.row.option.amount}
      </td>
      <td className="mb-2 table-cell p-2 text-left max-lg:hidden">
        {props.row.option.amount * props.row.option.price + " €"}
      </td>
      <td className="mb-2 table-cell p-2 text-left max-lg:hidden">
        {props.row.mailSent ? "Ja" : "Nein"}
      </td>
      <td className="mb-2 table-cell p-2 text-left ">
        <div className="flex gap-6">
          {props.row.option.amount > 1 && (
            <FontAwesomeIcon
              icon={faMinus}
              size="lg"
              onClick={() => props.row.decreaseTicketAmount()}
              className="cursor-pointer"
            />
          )}
          <FontAwesomeIcon
            icon={faTrashCan}
            size="lg"
            onClick={props.row.deleteTicket}
            className="cursor-pointer"
          />
        </div>
      </td>
    </tr>
  );
};

export default EventOrdersTableRow;
