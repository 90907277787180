import { TicketBuyIntent } from "./TicketItem";
import TicketList from "./TicketList";
import { PropsWithChildren } from "react";
import { TicketCreationIntent } from "./TicketItemCreationIntent";
import { getFreeSlotsDisplay, getSlotsText } from "../../utils/helper-funcs";
import { EVENT_OPTION_INFINITE_SLOTS } from "@code-on-the-rocks/ticket-flamingo-common";

interface TicketSectionProps {
  creationMode: boolean;
  creationTickets?: TicketCreationIntent[];
  headline: string;
  tickets: TicketBuyIntent[];
  slots: number;
  slotsSold: number;
  amountChangeable: boolean;
  onTicketAmountChange?: (ticketId: string, step: number) => void;
  onTicketsChange?: (tickets: TicketCreationIntent[]) => void;
}

const TicketSection = (props: PropsWithChildren<TicketSectionProps>) => {
  const {
    creationMode,
    creationTickets,
    headline,
    tickets,
    slots,
    slotsSold,
    amountChangeable,
    onTicketAmountChange,
    onTicketsChange,
  } = props;
  const freeSlots = getFreeSlotsDisplay(slots, slotsSold);

  const slotsBg = freeSlots <= 3 ? " text-red/70" : "";
  const slotsText = getSlotsText(freeSlots) + " frei";

  return (
    <div className="max-w-[726px] rounded-[10px] border-2 border-primaryDark px-6 py-3 max-sm:px-4">
      <h2 className="text-[28px] font-light max-sm:text-[22px]">{headline}</h2>
      {slots !== EVENT_OPTION_INFINITE_SLOTS && (
        <span
          className={
            "ml-1 text-[15px] font-light max-sm:text-[14px] " + slotsBg
          }
        >
          {slotsText}
        </span>
      )}
      <TicketList
        creationMode={creationMode}
        creationTickets={creationTickets}
        tickets={tickets}
        amountChangeable={amountChangeable}
        onTicketAmountChange={onTicketAmountChange}
        onTicketsChange={onTicketsChange}
      />
      {props.children}
    </div>
  );
};

export default TicketSection;
