import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

export type OrderStatus =
  | "COMPLETED"
  | "SAVED"
  | "APPROVED"
  | "VOIDED"
  | "PAYER_ACTION_REQUIRED"
  | "CREATED"
  | undefined;

export interface PayPalButtonWrapperItems {
  name: string;
  quantity: number;
  price: number;
}

interface PayPalButtonWrapperProps {
  items: PayPalButtonWrapperItems[];
  currency_code: string;
  onValidate: (orderId: string) => Promise<boolean>;
  onCancel: (data: Record<string, unknown>) => void;
  onError: (error: Record<string, unknown>) => void;
  onApprove: (orderId: string, orderStatus: OrderStatus) => void;
  LoadingIndicator?: React.ReactNode;
}

interface PayPalPurchaseUnitItem {
  name: string;

  quantity: string;
  unit_amount: { value: string; currency_code: string };
}

interface PayPalPurchaseAmount {
  value: string;
  currency_code: string;
  breakdown: {
    item_total: { value: string; currency_code: string };
  };
}

interface PayPalPurchaseUnit {
  items: PayPalPurchaseUnitItem[];
  amount: PayPalPurchaseAmount;
}

const PayPalButtonWrapper = (props: PayPalButtonWrapperProps) => {
  const {
    items,
    currency_code,
    onValidate,
    onCancel,
    onError,
    onApprove,
    LoadingIndicator,
  } = props;

  const purchaseUnit = createPurchaseUnit(items, currency_code);
  const [{ isPending }] = usePayPalScriptReducer();

  if (isPending)
    return <div className="mr-4 mt-[-20px] h-16">{LoadingIndicator}</div>;

  return (
    <div>
      <PayPalButtons
        style={{ layout: "horizontal", height: 40 }}
        forceReRender={[purchaseUnit]}
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [purchaseUnit],
            intent: "CAPTURE",
          });
        }}
        onClick={async (data, actions) => {
          return actions.resolve();
        }}
        onCancel={(data, actions) => {
          onCancel(data);
        }}
        onError={(error) => {
          onError(error);
        }}
        onApprove={async (data, actions) => {
          const validateResult = await onValidate(data.orderID);

          if (!validateResult) return onError({ message: "Validate Failed" });

          const order = await actions.order!.capture();
          onApprove(data.orderID, order.status);
        }}
      />
    </div>
  );
};

const createPurchaseUnit = (
  items: PayPalButtonWrapperItems[],
  currency_code: string
) => {
  const purchaseUnitItems: PayPalPurchaseUnitItem[] = items.map((item) => {
    return {
      name: item.name,
      quantity: item.quantity.toString(),
      unit_amount: {
        value: item.price.toString(),
        currency_code: currency_code,
      },
    };
  });

  const totalAmount = items.reduce(
    (sum, current) => sum + current.price * current.quantity,
    0
  );

  const purchaseAmount: PayPalPurchaseAmount = {
    value: totalAmount.toString(),
    currency_code: currency_code,
    breakdown: {
      item_total: {
        value: totalAmount.toString(),
        currency_code: currency_code,
      },
    },
  };

  const newPurchaseUnit: PayPalPurchaseUnit = {
    items: purchaseUnitItems,
    amount: purchaseAmount,
  };

  return newPurchaseUnit;
};

export default PayPalButtonWrapper;
