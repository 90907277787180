import http from "../services/httpService";

const apiEndpoint = process.env.REACT_APP_API_URL + "images";

export async function saveImage(image: File) {
  const formData = new FormData();
  formData.append("image", image);

  const response = await http.post<string>(apiEndpoint, formData, {
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  return response.data;
}
