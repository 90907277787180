import { EVENT_OPTION_INFINITE_SLOTS } from "@code-on-the-rocks/ticket-flamingo-common";
import { getFreeSlotsDisplay, getSlotsText } from "../../utils/helper-funcs";

interface IEventTicketIntroProps {
  slots: number;
  slotsSold: number;
}

const EventTicketIntro = (props: IEventTicketIntroProps) => {
  const slotsFree = getFreeSlotsDisplay(props.slots, props.slotsSold);

  const slotsText =
    (props.slots === EVENT_OPTION_INFINITE_SLOTS
      ? "∞"
      : getSlotsText(props.slots)) + " insgesamt";
  const slotsSoldText = getSlotsText(props.slotsSold) + " verkauft";
  const slotsFreeText = getSlotsText(slotsFree) + " frei";

  return (
    <div>
      <h1 className="text-[36px] font-light max-lg:text-[28px] max-sm:text-[22px]">
        Tickets
      </h1>
      <div className="mt-2 flex flex-col text-[20px] font-normal text-black/70  max-lg:text-[18px] max-sm:text-[16px]">
        <span>{slotsText}</span>
        <span>{slotsSoldText}</span>
        <span>{slotsFreeText}</span>
      </div>
    </div>
  );
};

export default EventTicketIntro;
