import { useEffect, useState } from "react";

const useDocumentHeightReporter = () => {
  const [height, setHeight] = useState(() =>
    manipulateHeight(document.body.offsetHeight)
  );
  const [prevHeight, setPrevHeight] = useState(height);

  useEffect(() => {
    const updateHeight = () => {
      const newHeight = manipulateHeight(document.body.offsetHeight);
      if (newHeight !== prevHeight) {
        setHeight(newHeight);
        setPrevHeight(newHeight);
        postMessage(newHeight);
      }
    };

    // Initial height set
    updateHeight();

    // MutationObserver to detect changes in the document
    const observer = new MutationObserver(updateHeight);

    // Observe changes in the body element's subtree
    observer.observe(document.body, {
      childList: true,
      subtree: true,
      attributes: true,
    });

    // Window resize event listener
    const handleResize = () => {
      updateHeight();
    };

    window.addEventListener("resize", handleResize);

    // Clean up on component unmount
    return () => {
      observer.disconnect();
      window.removeEventListener("resize", handleResize);
    };
  }, [prevHeight]);

  return height;
};

function manipulateHeight(height: number) {
  // add 10%
  const bufferedHeight = height * 1.1;

  // round up to 300s
  return Math.ceil(bufferedHeight / 300) * 300;
}

function postMessage(height: number) {
  const message = { type: "tf-doc-height", value: height };
  window.parent.postMessage(message, "*");
}

export default useDocumentHeightReporter;
