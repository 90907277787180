import { useEffect, useState } from "react";
import EventHeader from "../common/EventHeader";
import EventIntro from "../event/EventIntro";
import { getEventWithOrders, updateEvent } from "../../api/eventApi";
import { useParams } from "react-router-dom";
import { Event } from "@code-on-the-rocks/ticket-flamingo-common";
import EventTicketIntro from "../event/EventTicketIntro";
import EventOrdersTable from "../event-order/EventOrdersTable";
import { useHandleInvalidSession } from "../hooks/useHandlenvalidSession";
import EventNotFound from "../event/EventNotFound";
import { useSetupClientOrRedirect } from "../hooks/useSetupClientOrRedirect";
import _ from "lodash";
import { toast } from "react-toastify";

const EventOrdersPage = () => {
  const [clientId, clientName] = useSetupClientOrRedirect();
  const { eventId } = useParams();
  const invalidateSession = useHandleInvalidSession(clientName!);

  const [originalEvent, setOriginalEvent] = useState<Event | null>(null);
  const [eventNotFound, setEventNotFound] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const event = await getEventWithOrders(clientId, eventId!);

        if (event) {
          setOriginalEvent(event);
        } else {
          setEventNotFound(true);
        }
      } catch (error: any) {
        if (error?.response?.status === 401) invalidateSession();
      }
    }
    if (eventId && clientId) {
      fetchData();
      setEventNotFound(false);
    } else {
      setOriginalEvent(null);
      setEventNotFound(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);

  const deleteTicket = (orderId: string, optionId: string) => {
    if (!originalEvent || !eventId) return;

    const newEvent = _.cloneDeep(originalEvent);
    const orderIndex = newEvent.orders.findIndex((o) => o.id === orderId);

    newEvent.orders[orderIndex].options = newEvent.orders[
      orderIndex
    ].options.filter((o) => o.id !== optionId);

    setOriginalEvent(newEvent);
    updateEvent(clientId, eventId, newEvent);

    toast.success("Ticket removed!");
  };

  const updateTicketAmount = (
    orderId: string,
    optionId: string,
    newAmount: number
  ) => {
    if (!originalEvent || !eventId) return;

    const newEvent = _.cloneDeep(originalEvent);
    const orderIndex = newEvent.orders.findIndex((o) => o.id === orderId);
    const optionIndex = newEvent.orders[orderIndex].options.findIndex(
      (o) => o.id === optionId
    );

    newEvent.orders[orderIndex].options[optionIndex].amount = newAmount;

    setOriginalEvent(newEvent);
    updateEvent(clientId, eventId, newEvent);

    toast.success("Ticket amount adjusted!");
  };

  return (
    <div>
      <EventHeader to={`/${clientName}`} />
      <main>
        {originalEvent && (
          <>
            <div className="mb-10 flex flex-row justify-between max-lg:flex-col max-lg:gap-2">
              <EventIntro
                title={originalEvent.title + " - Buchungen"}
                location={originalEvent.location}
                startDate={originalEvent.start}
                endDate={originalEvent.end}
              />
              <EventTicketIntro
                slots={originalEvent.slots}
                slotsSold={originalEvent.slotsSold}
              />
            </div>
            <EventOrdersTable
              event={originalEvent}
              deleteTicket={deleteTicket}
              updateTicketAmount={updateTicketAmount}
            />
          </>
        )}
        {eventNotFound && <EventNotFound />}
      </main>
    </div>
  );
};

export default EventOrdersPage;
