import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface CheckoutMessageProps {
  title: string;
  subtitle: string;
  icon: IconProp;
  iconColor: string;
}

const CheckoutMessage = (props: CheckoutMessageProps) => {
  return (
    <div className="mb-5 grid grid-cols-[auto_1fr]">
      <div className="col-start-1 mr-3">
        <FontAwesomeIcon icon={props.icon} size="3x" color={props.iconColor} />
      </div>
      <h2 className="col-start-2 text-[28px] font-light ">{props.title}</h2>
      <span className="col-start-2 text-lg max-sm:text-base">
        {props.subtitle}
      </span>
    </div>
  );
};

export default CheckoutMessage;
