import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getClientByNameCached } from "../../api/clientApi";
import { applyColorTheme } from "../../utils/helper-funcs";

export function useSetupClientOrRedirect() {
  const { clientName } = useParams();
  const navigate = useNavigate();
  const [clientId, setClientId] = useState("");

  useEffect(() => {
    const fetchClientIdOrNavigate = async () => {
      const clientIdRes = await getClientByNameCached(clientName!);

      if (!clientIdRes) {
        navigate(`/not-found`);
      } else {
        setClientId(clientIdRes.id);

        applyColorTheme(
          clientIdRes.primaryColor,
          clientIdRes.secondaryColor,
          clientIdRes.primaryDarkColor,
          clientIdRes.secondaryDarkColor
        );
      }
    };

    if (clientName) fetchClientIdOrNavigate();
    else navigate(`/not-found`);
  });

  return [clientId, clientName ? clientName : ""];
}
