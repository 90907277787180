import jwtDecode from "jwt-decode";
import http from "./httpService";
import {
  AuthenticationResponse,
  AuthenticationToken,
  TOKEN_EXPIRED,
  UserLoginAttempt,
  UserWithoutPassword,
} from "@code-on-the-rocks/ticket-flamingo-common";

const apiEndpoint = process.env.REACT_APP_API_URL + "users";

const accessTokenKey = "ticket-flamingo-authAccessToken";
const refreshTokenKey = "ticket-flamingo-authRefreshToken";

init();
function init() {
  const accessToken = getAccessToken();
  if (accessToken) http.setAuthAccessToken(accessToken);

  http.setAuthRefreshTokenFunc(refresh);
  http.setTokenExpiredMessage(TOKEN_EXPIRED);
}

function getAccessToken() {
  return localStorage.getItem(accessTokenKey);
}

function setAccessToken(accessToken: string) {
  localStorage.setItem(accessTokenKey, accessToken);
  http.setAuthAccessToken(accessToken);
}

function getRefreshToken() {
  return localStorage.getItem(refreshTokenKey);
}

function setRefreshToken(refreshToken: string) {
  localStorage.setItem(refreshTokenKey, refreshToken);
}

async function login(loginAttempt: UserLoginAttempt) {
  try {
    const response = await http.post<AuthenticationResponse>(
      apiEndpoint + "/login",
      loginAttempt
    );

    setAccessToken(response.data.accessToken);
    setRefreshToken(response.data.refreshToken);

    return response.status;
  } catch (error: any) {
    return http.handleError(error);
  }
}

function logout() {
  localStorage.removeItem(accessTokenKey);
  localStorage.removeItem(refreshTokenKey);
  http.clearAuthAccessToken();
}

async function refresh() {
  try {
    const token = getRefreshToken();

    if (token) {
      const response = await http.post<AuthenticationToken>(
        apiEndpoint + "/refresh",
        { token }
      );

      if (response.status === 200) setAccessToken(response.data);

      return response.status;
    } else throw Error("refresh token not available");
  } catch (error: any) {
    return http.handleError(error);
  }
}

function getUser() {
  try {
    const token = getAccessToken();
    if (!token) return null;
    return jwtDecode<UserWithoutPassword>(token);
  } catch (error) {
    return null;
  }
}

function isRefreshTokenExpired() {
  const THRESHOLD_SECONDS = 30 * 60;
  try {
    const refreshToken = getRefreshToken();
    if (!refreshToken) return true;

    const decodedToken = jwtDecode<any>(refreshToken);
    const currentTime = Date.now() / 1000;

    return decodedToken.exp <= currentTime + THRESHOLD_SECONDS;
  } catch (error) {
    return true;
  }
}

const authService = {
  login,
  logout,
  refresh,
  getUser,
  isRefreshTokenExpired,
};

export default authService;
