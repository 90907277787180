import { z } from "zod";
import ValidatableForm, { useForm } from "../common/ValidatableForm";
import ValidatableFormField from "../common/ValidatableFormField";
import { Client } from "@code-on-the-rocks/ticket-flamingo-common";

interface ClientFormProps {
  client: Client;
  onClientUpdate: (client: Client) => void;
}

const ClientForm = ({ client, onClientUpdate }: ClientFormProps) => {
  const clientForm = useForm({
    schema: clientFormSchema,
  });

  const handleSubmit = async (values: z.infer<typeof clientFormSchema>) => {
    const newClient: Client = {
      id: client.id,
      name: values.clientName,
      primaryColor: values.primaryColor,
      primaryDarkColor: values.primaryDarkColor,
      secondaryColor: values.secondaryColor,
      secondaryDarkColor: values.secondaryDarkColor,
      notificationEmail: values.notificationEmail,
      paypalClientId: values.paypalClientId,
      paypalClientSecret: values.paypalClientSecret,
      emailHost: values.emailHost,
      emailPort: values.emailPort,
      emailUser: values.emailUser,
      emailPassword: values.emailPassword,
    };

    onClientUpdate(newClient);
  };

  return (
    <div>
      <h1 className="mb-4 text-[36px] font-light max-sm:text-2xl">
        Client Einstellungen
      </h1>

      <ValidatableForm form={clientForm} onSubmit={handleSubmit}>
        <div className="flex gap-14 max-xl:flex-col max-xl:gap-6">
          <div>
            <ValidatableFormField
              readOnly
              label="Client Name"
              type="text"
              defaultValue={client.name}
              additionalClasses="w-[250px]"
              {...clientForm.register("clientName")}
            />

            <div className="flex items-center gap-4">
              <ValidatableFormField
                label="Primär Farbe"
                type="text"
                defaultValue={client.primaryColor}
                additionalClasses="w-[250px]"
                {...clientForm.register("primaryColor")}
              />

              <div
                className="h-12 w-12 rounded-lg"
                style={{
                  backgroundColor:
                    clientForm.watch("primaryColor") || client.primaryColor,
                }}
              ></div>
            </div>
            <div className="flex items-center gap-4">
              <ValidatableFormField
                label="Primär Farbe dunkel"
                type="text"
                defaultValue={client.primaryDarkColor}
                additionalClasses="w-[250px]"
                {...clientForm.register("primaryDarkColor")}
              />
              <div
                className="h-12 w-12 rounded-lg"
                style={{
                  backgroundColor:
                    clientForm.watch("primaryDarkColor") ||
                    client.primaryDarkColor,
                }}
              ></div>
            </div>
            <div className="flex items-center gap-4">
              <ValidatableFormField
                label="Sekundär Farbe"
                type="text"
                defaultValue={client.secondaryColor}
                additionalClasses="w-[250px]"
                {...clientForm.register("secondaryColor")}
              />
              <div
                className="h-12 w-12 rounded-lg"
                style={{
                  backgroundColor:
                    clientForm.watch("secondaryColor") || client.secondaryColor,
                }}
              ></div>
            </div>
            <div className="flex items-center gap-4">
              <ValidatableFormField
                label="Sekundär Farbe dunkel"
                type="text"
                defaultValue={client.secondaryDarkColor}
                additionalClasses="w-[250px]"
                {...clientForm.register("secondaryDarkColor")}
              />
              <div
                className="h-12 w-12 rounded-lg"
                style={{
                  backgroundColor:
                    clientForm.watch("secondaryDarkColor") ||
                    client.secondaryDarkColor,
                }}
              ></div>
            </div>
          </div>
          <div>
            <ValidatableFormField
              label="Email Host"
              type="text"
              defaultValue={client.emailHost}
              additionalClasses="w-[300px]"
              {...clientForm.register("emailHost")}
            />

            <ValidatableFormField
              label="Email Port"
              type="number"
              defaultValue={client.emailPort}
              additionalClasses="w-[300px]"
              {...clientForm.register("emailPort")}
            />

            <ValidatableFormField
              label="Email User"
              type="text"
              defaultValue={client.emailUser}
              additionalClasses="w-[300px]"
              {...clientForm.register("emailUser")}
            />

            <ValidatableFormField
              label="Email Password"
              type="password"
              defaultValue={client.emailPassword}
              additionalClasses="w-[300px]"
              {...clientForm.register("emailPassword")}
            />
          </div>
          <div>
            <ValidatableFormField
              label="Paypal Client Id"
              type="text"
              defaultValue={client.paypalClientId}
              additionalClasses="w-[300px]"
              {...clientForm.register("paypalClientId")}
            />

            <ValidatableFormField
              label="Paypal Client Secret"
              type="text"
              defaultValue={client.paypalClientSecret}
              additionalClasses="w-[300px]"
              {...clientForm.register("paypalClientSecret")}
            />

            <ValidatableFormField
              label="Notification Email"
              type="email"
              defaultValue={client.notificationEmail}
              additionalClasses="w-[300px]"
              {...clientForm.register("notificationEmail")}
            />
          </div>
        </div>
        <ValidatableFormField
          label="Update Client"
          type="submit"
          additionalClasses="mr-auto"
        />
      </ValidatableForm>
    </div>
  );
};

const hexColorValidationRegex =
  /^#(?:[A-Fa-f0-9]{3}){1,2}$|^#(?:[A-Fa-f0-9]{4}){1,2}$/;

export const clientFormSchema = z.object({
  clientName: z
    .string()
    .toLowerCase()
    .min(1, "Bitte gib den Namen des Clients ein."),
  primaryColor: z
    .string()
    .regex(
      hexColorValidationRegex,
      "Bitte gib einen gültigen Hex-Farbecode ein."
    ),
  primaryDarkColor: z
    .string()
    .regex(
      hexColorValidationRegex,
      "Bitte gib einen gültigen Hex-Farbecode ein."
    ),
  secondaryColor: z
    .string()
    .regex(
      hexColorValidationRegex,
      "Bitte gib einen gültigen Hex-Farbecode ein."
    ),
  secondaryDarkColor: z
    .string()
    .regex(
      hexColorValidationRegex,
      "Bitte gib einen gültigen Hex-Farbecode ein."
    ),
  paypalClientId: z.string().min(1, "Bitte gib deine PayPal-ClientID ein."),
  paypalClientSecret: z
    .string()
    .min(1, "Bitte gib dein PayPal-ClientSecret ein."),
  notificationEmail: z
    .string()
    .email("Bitte gib eine gütlige Email Adresse ein."),
  emailHost: z.string().min(1, "Bitte gib den Host deines Email-Servers ein."),
  emailPort: z.coerce
    .number()
    .int()
    .min(1, "Bitte gib den Port deines Email-Servers ein."),
  emailUser: z
    .string()
    .min(1, "Bitte gib den Benutzernamen deines Email-Servers ein."),
  emailPassword: z
    .string()
    .min(1, "Bitte gib das Passwort deines Email-Servers ein."),
});

export default ClientForm;
