import EventHeader from "../common/EventHeader";
import { useNavigate } from "react-router-dom";
import { useSessionContext } from "../context/SessionContext";
import auth from "../../services/authService";
import { toast } from "react-toastify";
import {
  Client,
  UserWithoutPassword,
} from "@code-on-the-rocks/ticket-flamingo-common";
import { useSetupClientOrRedirect } from "../hooks/useSetupClientOrRedirect";
import LoginForm from "../profile/LoginForm";
import ClientForm from "../profile/ClientForm";
import { useEffect, useState } from "react";
import { getPrivateClientById, updateClient } from "../../api/clientApi";
import { useHandleInvalidSession } from "../hooks/useHandlenvalidSession";
import UserForm from "../profile/UserForm";
import _ from "lodash";

//TODO: look at this hole Page and Form setup, get dependecies right, get update routines right, who is responsible for what?
const ProfilePage = () => {
  const [session, setSession] = useSessionContext();
  const navigate = useNavigate();
  const [clientId, clientName] = useSetupClientOrRedirect();
  const invalidateSession = useHandleInvalidSession(clientName!);
  const [origClient, setOrigClient] = useState<Client | null>(null);

  useEffect(() => {
    const fetchPrivateClient = async () => {
      try {
        const client = await getPrivateClientById(clientId);

        if (client) setOrigClient(client);
      } catch (error: any) {
        if (error?.response?.status === 401 && session.user)
          invalidateSession();
      }
    };
    if (clientId && session.user) fetchPrivateClient();
  }, [clientId]);

  const handleLogout = () => {
    setSession({ ...session, user: null, redirectPath: "" });
    auth.logout();
    navigate(session.redirectPath ? session.redirectPath : `/${clientName}`);
  };

  const handleSuccessfulLogin = () => {
    setSession({ ...session, user: auth.getUser(), redirectPath: "" });
    navigate(session.redirectPath ? session.redirectPath : `/${clientName}`);
  };

  const handleSuccessfulUserUpdate = async (user: UserWithoutPassword) => {
    await auth.refresh();
    setSession({ ...session, user: auth.getUser(), redirectPath: "" });
  };

  const handleClientUpdate = async (client: Client) => {
    try {
      if (!_.isEqual(origClient, client)) {
        await updateClient(client);
        toast.success("Client updated!");
        setOrigClient(client);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 400)
        return toast.error("Invalid data has been passed to server.");
      else if (error.response && error.response.status === 401) {
        invalidateSession();
      } else return toast.error("Something went wrong.");
    }
  };

  return (
    <div>
      <EventHeader to={`/${clientName}`} />
      <main>
        {!session.user && (
          <LoginForm
            clientId={clientId}
            onSuccessfulLogin={handleSuccessfulLogin}
          />
        )}
        {session.user && origClient && (
          <div className="flex flex-col gap-14">
            <ClientForm
              client={origClient}
              onClientUpdate={handleClientUpdate}
            />
            <UserForm
              user={session.user}
              onSuccessfulUserUpdate={handleSuccessfulUserUpdate}
              onLogout={handleLogout}
            />
          </div>
        )}
      </main>
    </div>
  );
};

export default ProfilePage;
