import { toast } from "react-toastify";
import { useSessionContext } from "../context/SessionContext";
import { useLocation, useNavigate } from "react-router-dom";
import auth from "../../services/authService";

export function useHandleInvalidSession(clientName: string) {
  const [, setSession] = useSessionContext();
  const navigate = useNavigate();
  const currentLocation = useLocation();

  const logout = () => {
    toast.error("Session abgelaufen");
    setSession({ user: null, redirectPath: currentLocation.pathname });
    auth.logout();
    navigate(`/${clientName}/profile`);
  };

  return logout;
}
