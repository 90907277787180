import { EVENT_OPTION_INSERT_NEW_ID } from "@code-on-the-rocks/ticket-flamingo-common";
import TicketItem, { TicketBuyIntent } from "./TicketItem";
import TicketItemCreationIntent, {
  TicketCreationIntent,
} from "./TicketItemCreationIntent";

interface TicketListProps {
  creationMode: boolean;
  creationTickets?: TicketCreationIntent[];
  tickets: TicketBuyIntent[];
  amountChangeable: boolean;
  onTicketAmountChange?: (ticketId: string, step: number) => void;
  onTicketsChange?: (tickets: TicketCreationIntent[]) => void;
}

const TicketList = (props: TicketListProps) => {
  const {
    creationMode,
    creationTickets,
    tickets,
    amountChangeable,
    onTicketAmountChange,
    onTicketsChange,
  } = props;

  const [
    getTicketSummaryAmount,
    getTicketSummaryPrice,
    onCreationTicketAdd,
    onCreationTicketChange,
    onCreationTicketRemove,
  ] = useTicketLogic(tickets, creationTickets, onTicketsChange);

  const showTable =
    !creationMode || (creationMode && creationTickets!.length > 0);
  const ticketNameHeader = creationMode ? "Ticketname" : "";

  return (
    <>
      {creationMode && (
        <input
          type={"button"}
          value={"Neues Ticket"}
          className={"btn-primary"}
          onClick={onCreationTicketAdd}
        />
      )}
      {showTable && (
        <table className="table w-full">
          <thead className="table-header-group">
            <tr className="table-row">
              <td className="table-cell text-left">{ticketNameHeader}</td>
              <td className="table-cell w-[10%] pb-1 text-left max-sm:w-[15%] max-[450px]:w-[22%]">
                Preis
              </td>
              {!creationMode && (
                <td className="table-cell w-[20%] pb-1 text-center max-sm:w-[20%] max-[450px]:w-[28%]">
                  Anzahl
                </td>
              )}
            </tr>
          </thead>

          <tbody className="table-row-group">
            {!creationMode &&
              tickets.map((ticket) => (
                <TicketItem
                  key={ticket.id}
                  ticket={ticket}
                  amountChangeable={amountChangeable}
                  onTicketAmountChange={onTicketAmountChange}
                />
              ))}

            {creationMode &&
              creationTickets?.map((ticket) => (
                <TicketItemCreationIntent
                  key={ticket.id}
                  ticket={ticket}
                  onTicketChange={onCreationTicketChange}
                  onTicketRemove={onCreationTicketRemove}
                />
              ))}
          </tbody>
        </table>
      )}

      {!creationMode && (
        <>
          <div className="h-[1px] bg-primaryDark"></div>
          <div className="my-3 flex gap-3 text-[16px]">
            <span>{"Anzahl Tickets: " + getTicketSummaryAmount()}</span>
            <span>{"Summe: " + getTicketSummaryPrice() + " €"}</span>
          </div>
        </>
      )}
    </>
  );
};

function useTicketLogic(
  tickets: TicketBuyIntent[],
  creationTickets: TicketCreationIntent[] | undefined,
  onTicketsChange?: (tickets: TicketCreationIntent[]) => void
): [
  () => void,
  () => void,
  () => void,
  (ticket: TicketCreationIntent) => void,
  (ticketId: string) => void
] {
  const getTicketSummaryAmount = () => {
    return tickets.reduce(
      (sum, currentTicketAmount) => sum + currentTicketAmount.amount,
      0
    );
  };

  const getTicketSummaryPrice = () => {
    return tickets.reduce(
      (sum, currentTicket) => sum + currentTicket.price * currentTicket.amount,
      0
    );
  };

  const onCreationTicketAdd = () => {
    if (onTicketsChange)
      onTicketsChange([
        {
          id: EVENT_OPTION_INSERT_NEW_ID + crypto.randomUUID(),
          name: "",
          price: 0,
          // slots: 0,
        },
        ...creationTickets!,
      ]);
  };

  const onCreationTicketChange = (ticket: TicketCreationIntent) => {
    if (onTicketsChange) {
      const newTickets = creationTickets!.map((ticket) => ({ ...ticket }));
      const index = newTickets.findIndex((t) => t.id === ticket.id);
      newTickets[index] = ticket;
      onTicketsChange([...newTickets]);
    }
  };

  const onCreationTicketRemove = (ticketId: string) => {
    if (onTicketsChange) {
      const newTickets = creationTickets!.map((ticket) => ({ ...ticket }));
      const index = newTickets.findIndex((ticket) => ticket.id === ticketId);
      newTickets.splice(index, 1);
      onTicketsChange([...newTickets]);
    }
  };

  return [
    getTicketSummaryAmount,
    getTicketSummaryPrice,
    onCreationTicketAdd,
    onCreationTicketChange,
    onCreationTicketRemove,
  ];
}

export default TicketList;
