import EventListItemButtons from "./EventListItemButtons";
import { getFreeSlotsDisplay, getSlotsText } from "../../utils/helper-funcs";
import {
  EVENT_OPTION_INFINITE_SLOTS,
  Event,
} from "@code-on-the-rocks/ticket-flamingo-common";
import clsx from "clsx";

const CRITICAL_SLOTS_LEFT = 3;
const BULLET_POINT_START = "  ·  ";
const SLOTS_TEXT_FREE = "frei";
const SLOTS_TEXT_SOLD_OUT = "Ausgebucht";

interface EventListItemProps {
  event: Event;
  onDeleteEvent: (id: string) => void;
  onDuplicateEvent: (id: string) => void;
}

const EventListItem = (props: EventListItemProps) => {
  const { event, onDeleteEvent, onDuplicateEvent } = props;

  const {
    eventSoldOut,
    slotsClass,
    mainBackgroundClass,
    timeBackgroundClass,
    slotsText,
  } = prepareComponent(event);

  return (
    <div
      key={event.id}
      className={clsx("mb-[3px] flex rounded-[10px]", mainBackgroundClass)}
    >
      <div
        className={clsx(
          "flex w-[75px] shrink-0 flex-col items-center justify-around rounded-[10px] py-3 text-[22px] font-normal",
          timeBackgroundClass
        )}
      >
        <span>{event.start.toHumanReadableTimeString()}</span>

        <span className="text-black/50">
          {event.end.toHumanReadableTimeString()}
        </span>
      </div>

      <div className="ml-4 mr-2 flex flex-col justify-between overflow-hidden py-3 max-lg:py-2 max-sm:justify-center">
        <span className="inline-block flex-shrink overflow-hidden text-ellipsis whitespace-nowrap text-[22px] font-normal max-lg:text-[20px]">
          {event.title}
        </span>
        <div className="inline-block text-[18px] font-normal text-black/70 max-lg:flex max-lg:flex-col max-lg:text-[14px] max-lg:leading-5">
          {renderWithBullet(event.descriptionShort)}
          {renderWithBullet(event.location)}
          {renderWithBullet(slotsText, true, slotsClass)}
        </div>
      </div>
      <div className="ml-auto flex items-center text-[22px] font-medium">
        <EventListItemButtons
          eventTitle={event.title}
          eventId={event.id}
          bookedOut={eventSoldOut}
          onDeleteEvent={onDeleteEvent}
          onDuplicateEvent={onDuplicateEvent}
        />
      </div>
    </div>
  );
};

const renderWithBullet = (
  text: string,
  fontStrong: boolean = false,
  additionalClasses: string = ""
) => {
  return (
    text.trim() && (
      <span
        className={clsx(
          "max-lg:flex-shrink max-lg:overflow-hidden max-lg:text-ellipsis max-lg:whitespace-nowrap",
          { "font-medium": fontStrong },
          additionalClasses
        )}
      >
        {`${BULLET_POINT_START}${text}`}
      </span>
    )
  );
};

function prepareComponent(event: Event) {
  const freeSlots = getFreeSlotsDisplay(event.slots, event.slotsSold);
  const eventSoldOut =
    freeSlots === 0 && event.slots !== EVENT_OPTION_INFINITE_SLOTS;

  const slotsText =
    freeSlots > 0
      ? getSlotsText(freeSlots) + ` ${SLOTS_TEXT_FREE}`
      : SLOTS_TEXT_SOLD_OUT;

  const slotsClass = clsx({
    hidden: event.slots === EVENT_OPTION_INFINITE_SLOTS,
    "text-red/70": freeSlots <= CRITICAL_SLOTS_LEFT && !eventSoldOut,
  });

  const mainBackgroundClass = clsx({
    "bg-primary": !eventSoldOut,
    "bg-grey": eventSoldOut,
  });

  const timeBackgroundClass = clsx({
    "bg-primaryDark": !eventSoldOut,
    "bg-greyDark": eventSoldOut,
  });

  return {
    eventSoldOut,
    slotsClass,
    mainBackgroundClass,
    timeBackgroundClass,
    slotsText,
  };
}

export default EventListItem;
