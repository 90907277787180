import { DateTime } from "@code-on-the-rocks/ts-datetime";

interface CacheItem<T> {
  timestamp: DateTime;
  data: T;
}

export abstract class AbstractCache<T> {
  private cache: Map<string, CacheItem<T>>;
  private validityDurationMs: number;

  protected constructor(validityDurationMs: number) {
    this.cache = new Map<string, CacheItem<T>>();
    this.validityDurationMs = validityDurationMs;
  }

  private isItemValid(item: CacheItem<T>): boolean {
    const now = DateTime.now();
    const expiryTime = item.timestamp.add(
      DateTime.FromMilliseconds(this.validityDurationMs)
    );
    return now.milliseconds <= expiryTime.milliseconds;
  }

  public has(name: string): boolean {
    const item = this.cache.get(name);
    return item !== undefined && this.isItemValid(item);
  }

  public get(name: string): T | null {
    if (this.has(name)) {
      return this.cache.get(name)!.data;
    }
    return null;
  }

  public set(name: string, data: T): void {
    const item: CacheItem<T> = { timestamp: DateTime.now(), data };
    this.cache.set(name, item);
  }

  public delete(name: string): boolean {
    return this.cache.delete(name);
  }
}
