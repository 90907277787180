import { ThreeDots } from "react-loader-spinner";

interface LoadingIndicatorProps {
  show: boolean;
}

const LoadingIndicator = (props: LoadingIndicatorProps) => {
  return props.show ? (
    <ThreeDots
      height="80"
      width="80"
      radius="9"
      color="#404040"
      ariaLabel="three-dots-loading"
      wrapperStyle={{}}
      visible={true}
    />
  ) : (
    <></>
  );
};

export default LoadingIndicator;
