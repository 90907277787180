import { useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import { useClickOutsideAlerter } from "../hooks/useClickOutsideAlerter";

interface NavigationProps {
  clientName: string;
}

const Navigation = (props: NavigationProps) => {
  const [showSubMenu, setShowSubMenu] = useState(false);
  const subMenuRef = useRef(null);
  useClickOutsideAlerter(subMenuRef, () => setShowSubMenu(false));

  const toggleSubMenu = () => {
    setShowSubMenu((current) => {
      return !current;
    });
  };

  return (
    <nav className="h-full">
      <div
        onClick={toggleSubMenu}
        className="flex h-full cursor-pointer items-center rounded-[10px] bg-primaryDark px-[20px] text-[26px] font-normal hover:text-white hover:opacity-80 max-sm:text-xl"
      >
        +
      </div>
      {showSubMenu && (
        <div
          ref={subMenuRef}
          className={
            "absolute right-1 flex flex-col rounded-[10px] bg-primary px-4 py-2 text-[18px] shadow-lg max-sm:text-base"
          }
          onClick={toggleSubMenu}
        >
          <NavLink
            className={"hover:text-white"}
            to={`/${props.clientName}/events/new`}
          >
            New Event
          </NavLink>
        </div>
      )}
    </nav>
  );
};

export default Navigation;
