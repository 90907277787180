import { useEffect, useState } from "react";
import { z } from "zod";
import { datetimeLocal } from "../../utils/helper-funcs";
import ImageUploader from "../common/ImageUploader";
import TicketSection from "../event-ticket/TicketSection";
import { TicketCreationIntent } from "../event-ticket/TicketItemCreationIntent";
import ValidatableForm, { useForm } from "../common/ValidatableForm";
import ValidatableFormField from "../common/ValidatableFormField";
import { getEventWithOrders, saveEvent, updateEvent } from "../../api/eventApi";
import {
  EventWithoutIdImplementation,
  EventFunctions,
  EVENT_OPTION_INFINITE_SLOTS,
} from "@code-on-the-rocks/ticket-flamingo-common";
import { DateTime } from "@code-on-the-rocks/ts-datetime";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ErrorLabel from "../common/ErrorLabel";
import _ from "lodash";
import { UseFormReturn } from "react-hook-form";
import EventNotFound from "./EventNotFound";
import { useHandleInvalidSession } from "../hooks/useHandlenvalidSession";
import { useSetupClientOrRedirect } from "../hooks/useSetupClientOrRedirect";

const EventEditForm = () => {
  const {
    form,
    handleSubmit,
    eventNotFound,
    originalEvent,
    setImageUrl,
    imageUploading,
    setImageUploading,
    previewUrl,
    setPreviewUrl,
    showUploadingError,
    ticketCreationIntents,
    setTicketCreationIntents,
  } = useEventEditFormLogic();

  return (
    <>
      {!eventNotFound && (
        <ValidatableForm form={form} onSubmit={handleSubmit}>
          <div className="flex flex-row gap-4 max-lg:flex-col max-lg:gap-2">
            {renderBasicFields(form, originalEvent)}
            {renderSpecialFields(
              setImageUrl,
              imageUploading,
              setImageUploading,
              previewUrl,
              setPreviewUrl,
              showUploadingError,
              ticketCreationIntents,
              setTicketCreationIntents
            )}
            <ValidatableFormField
              label="Speichern"
              type="submit"
              additionalClasses="mr-auto self-start hidden max-lg:block"
            />
          </div>
        </ValidatableForm>
      )}
      {eventNotFound && <EventNotFound />}
    </>
  );
};

function renderBasicFields(
  form: UseFormReturn<z.infer<typeof formSchema>>,
  originalEvent: EventWithoutIdImplementation
) {
  return (
    <div className="flex flex-col gap-2">
      <ValidatableFormField
        autoFocus
        label="Titel"
        type="text"
        placeholder=""
        additionalClasses=""
        defaultValue={originalEvent.title}
        {...form.register("title")}
      />

      <div className="mr-auto flex flex-row justify-between gap-4 max-lg:justify-start max-sm:flex-col max-sm:gap-2">
        <ValidatableFormField
          label="Start"
          type="datetime-local"
          additionalClasses=""
          defaultValue={
            originalEvent.start.milliseconds === 0
              ? ""
              : datetimeLocal(new Date(originalEvent.start.milliseconds))
          }
          {...form.register("startDate", {
            valueAsDate: true,
          })}
        />

        <ValidatableFormField
          label="End"
          type="datetime-local"
          additionalClasses=""
          defaultValue={
            originalEvent.end.milliseconds === 0
              ? ""
              : datetimeLocal(new Date(originalEvent.end.milliseconds))
          }
          {...form.register("endDate", {
            valueAsDate: true,
          })}
        />
      </div>

      <ValidatableFormField
        label="Veranstaltungsort"
        type="text"
        placeholder=""
        additionalClasses=""
        defaultValue={originalEvent.location}
        {...form.register("location")}
      />

      <ValidatableFormField
        label="Max. Teilnehmeranzahl"
        type="number"
        placeholder=""
        additionalClasses="w-[100px]"
        defaultValue={originalEvent.slots}
        {...form.register("slots", { valueAsNumber: true })}
      />

      <ValidatableFormField
        label="Kurzbeschreibung"
        type="text"
        placeholder=""
        additionalClasses=""
        defaultValue={originalEvent.descriptionShort}
        {...form.register("descriptionShort")}
      />

      <ValidatableFormField
        label="Beschreibung"
        type="textarea"
        placeholder=""
        additionalClasses=""
        defaultValue={originalEvent.descriptionLong}
        {...form.register("descriptionLong")}
      />
      <ValidatableFormField
        label="Speichern"
        type="submit"
        additionalClasses="mr-auto self-start max-lg:hidden"
      />
    </div>
  );
}

function renderSpecialFields(
  setImageUrl: (url: string) => void,
  imageUploading: boolean,
  setImageUploading: (uploading: boolean) => void,
  previewUrl: string,
  setPreviewUrl: (url: string) => void,
  showUploadingError: boolean,
  ticketCreationIntents: TicketCreationIntent[],
  setTicketCreationIntents: (tickets: TicketCreationIntent[]) => void
) {
  return (
    <div className="flex flex-col gap-2 max-lg:gap-4">
      <div>
        <ImageUploader
          onImageChange={setImageUrl}
          imageUploading={imageUploading}
          setImageUploading={setImageUploading}
          previewUrl={previewUrl}
          setPreviewUrl={setPreviewUrl}
        />
        {showUploadingError && <ErrorLabel text="Image still uploading" />}
      </div>

      <TicketSection
        creationMode={true}
        creationTickets={ticketCreationIntents}
        headline="Tickets"
        slots={EVENT_OPTION_INFINITE_SLOTS}
        slotsSold={EVENT_OPTION_INFINITE_SLOTS}
        tickets={[]}
        amountChangeable={false}
        onTicketsChange={setTicketCreationIntents}
      />
    </div>
  );
}

function useEventEditFormLogic() {
  const [clientId, clientName] = useSetupClientOrRedirect();
  const { eventId } = useParams();
  const invalidateSession = useHandleInvalidSession(clientName!);
  const navigate = useNavigate();
  const [originalEvent, setOriginalEvent] = useState(
    EventFunctions.createEmptyEventWithoutId()
  );
  const [eventNotFound, setEventNotFound] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [showUploadingError, setShowUploadingError] = useState(false);
  const [ticketCreationIntents, setTicketCreationIntents] = useState<
    TicketCreationIntent[]
  >([]);

  useEffect(() => {
    async function fetchData() {
      if (!clientId) return;

      try {
        const event = await getEventWithOrders(clientId, eventId!);

        if (event) {
          setOriginalEvent(EventFunctions.transformToEventWithoutId(event));
          setTicketCreationIntents(_.cloneDeep(event.options));
          event.image &&
            setPreviewUrl(process.env.REACT_APP_IMAGE_URL + event.image);
          setImageUrl(event.image);
          form.reset();
        } else {
          setEventNotFound(true);
        }
      } catch (error: any) {
        if (error?.response?.status === 401) invalidateSession();
      }
    }
    if (eventId) {
      fetchData();
    } else {
      setOriginalEvent(EventFunctions.createEmptyEventWithoutId());
      setTicketCreationIntents([]);
      setPreviewUrl("");
      setImageUrl("");
      form.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [eventId, clientId]);

  const form = useForm({ schema: formSchema });

  const handleSubmit = async (values: z.infer<typeof formSchema>) => {
    if (imageUploading) {
      setShowUploadingError(true);
      return;
    } else {
      setShowUploadingError(false);
    }

    if (ticketCreationIntents.some((ticket) => ticket.name.trim() === ""))
      return;

    try {
      const newEvent = new EventWithoutIdImplementation(
        clientId,
        values.title,
        values.descriptionShort,
        values.descriptionLong,
        values.location,
        DateTime.FromString(datetimeLocal(values.startDate)),
        DateTime.FromString(datetimeLocal(values.endDate)),
        values.slots,
        0,
        imageUrl,
        ticketCreationIntents,
        originalEvent.orders
      );

      if (eventId) {
        if (!_.isEqual(originalEvent, newEvent))
          await updateEvent(clientId, eventId, newEvent);
      } else {
        await saveEvent(newEvent);
      }
      toast.success("Event saved!");
    } catch (error: any) {
      if (error.response && error.response.status === 400)
        return toast.error("Invalid data has been passed to server.");
      else if (error.response && error.response.status === 401) {
        invalidateSession();
      } else return toast.error("Something went wrong.");
    }

    navigate(`/${clientName}`);
  };

  return {
    form,
    handleSubmit,
    eventNotFound,
    originalEvent,
    setImageUrl,
    imageUploading,
    setImageUploading,
    previewUrl,
    setPreviewUrl,
    showUploadingError,
    ticketCreationIntents,
    setTicketCreationIntents,
  };
}

const formSchema = z
  .object({
    title: z
      .string()
      .min(1, "Please enter a name")
      .max(60, "There are max. 60 characters allowed"),
    location: z.string(),
    startDate: z.date({
      errorMap: (issue, _ctx) => {
        return { message: "Please enter a start date" };
      },
    }),
    endDate: z.date({
      errorMap: (issue, _ctx) => {
        return { message: "Please enter a end date" };
      },
    }),
    descriptionShort: z
      .string()
      .max(80, "There are max. 80 characters allowed"),
    descriptionLong: z.string(),
    slots: z
      .number({
        errorMap: (issue, _ctx) => {
          return { message: "Please specify maximum number of participants" };
        },
      })
      .min(EVENT_OPTION_INFINITE_SLOTS),
  })
  .refine((data) => data.startDate <= data.endDate, {
    message: "The end date must be later than the start date",
    path: ["endDate"],
  });

export default EventEditForm;
