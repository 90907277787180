import {
  Client,
  ClientFunctions,
  PublicClient,
} from "@code-on-the-rocks/ticket-flamingo-common";
import http from "../services/httpService";
import { PublicClientCache } from "../utils/client-cache";

export const apiEndpoint = process.env.REACT_APP_API_URL + "clients";

const clientCache = PublicClientCache.getInstance();

export async function getClientByNameCached(name: string) {
  if (clientCache.has(name)) return clientCache.get(name)!;

  const clientData = await getClientByName(name);
  if (!clientData) return "";

  clientCache.set(name, clientData);
  return clientData;
}

export async function getPaypalClientIdByNameCached(name: string) {
  if (clientCache.has(name)) return clientCache.get(name)!.paypalClientId;

  const clientData = await getClientByName(name);
  if (!clientData) return "";

  clientCache.set(name, clientData);
  return clientData.paypalClientId;
}

async function getClientByName(name: string) {
  try {
    const response = await http.get<PublicClient>(
      `${apiEndpoint}/name/${name}`
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    return null;
  }
}

export async function getPrivateClientById(id: string) {
  const response = await http.get<Client>(`${apiEndpoint}/id-private/${id}`);
  return response.data;
}

export async function updateClient(client: Client) {
  const response = await http.put<Event>(`${apiEndpoint}/${client.id}`, client);

  if (response.status === 200)
    clientCache.set(client.name, ClientFunctions.toPublicClient(client));

  return response;
}
