interface EventFilterSelectorProps {
  activeEventFilter: EVENT_FILTER;
  setEventFilter: (eventFilter: EVENT_FILTER) => void;
}

export enum EVENT_FILTER {
  UPCOMING,
  PAST,
  DELETED,
}

const EventFilterSelector = (props: EventFilterSelectorProps) => {
  const filters = [
    {
      filter: EVENT_FILTER.UPCOMING,
      displayText: "Anstehend",
    },
    {
      filter: EVENT_FILTER.PAST,
      displayText: "Vergangen",
    },
    // {
    //   filter: EVENT_FILTER.DELETED,
    //   displayText: "Gelöscht",
    // },
  ];

  return (
    <div className="flex gap-3">
      {filters.map((f) => (
        <button
          key={f.filter}
          className={
            "mt-5 inline-block text-[16px] font-medium text-primaryDark hover:text-primary " +
            (f.filter === props.activeEventFilter ? "underline" : "")
          }
          onClick={() => props.setEventFilter(f.filter)}
        >
          {f.displayText}
        </button>
      ))}
    </div>
  );
};
export default EventFilterSelector;
