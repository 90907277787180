import { ReactNode, createContext, useContext, useState } from "react";
import auth from "../../services/authService";
import { UserWithoutPassword } from "@code-on-the-rocks/ticket-flamingo-common";
export type Session = {
  user: UserWithoutPassword | null;
  redirectPath: string;
};

export const initialSession: Session = {
  redirectPath: "",
  user: null,
};

export const SessionContext = createContext<
  [Session, (session: Session) => void]
>([initialSession, () => {}]);

SessionContext.displayName = "SessionContext";

export const useSessionContext = () => useContext(SessionContext);

export const SessionContextProvider = ({
  children,
}: {
  children: ReactNode | undefined;
}) => {
  const [sessionState, setSessionState] = useState({
    redirectPath: "",
    user: auth.getUser(),
  });
  const defaultSessionContext: [Session, typeof setSessionState] = [
    sessionState,
    setSessionState,
  ];

  return (
    <SessionContext.Provider value={defaultSessionContext}>
      {children}
    </SessionContext.Provider>
  );
};
