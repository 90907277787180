import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  EVENT_OPTION_INFINITE_SLOTS,
  Event,
} from "@code-on-the-rocks/ticket-flamingo-common";
import EventHeader from "../common/EventHeader";
import TicketSection from "../event-ticket/TicketSection";
import { TicketBuyIntent } from "../event-ticket/TicketItem";
import CustomerInfoForm, {
  CustomerInfo,
} from "../event-ticket/CustomerInfoForm";
import { checkOutContext } from "../context/CheckOutContext";
import { getEvent } from "../../api/eventApi";
import EventNotFound from "../event/EventNotFound";
import EventIntro from "../event/EventIntro";
import { DateTime } from "@code-on-the-rocks/ts-datetime";
import { useSetupClientOrRedirect } from "../hooks/useSetupClientOrRedirect";

const EventCustomerPage = () => {
  const [
    clientName,
    showError,
    showTicketSection,
    event,
    ticketBuyIntents,
    hideFeatureImage,
    setHideFeatureImage,
    hideCustomerInfo,
    setHideCustomerInfo,
    handleChangeTicketAmount,
    handleSubmitToCheckout,
  ] = useEventCustomerPageLogic();

  return (
    <div>
      <EventHeader to={`/${clientName}`} />

      <main>
        {event && (
          <>
            <EventIntro
              title={event.title}
              location={event.location}
              startDate={event.start}
              endDate={event.end}
            />
            <img
              src={process.env.REACT_APP_IMAGE_URL + event.image}
              onLoad={() => setHideFeatureImage(false)}
              alt="feature"
              className="mt-4 h-[390px] w-[726px] rounded-[10px] object-cover max-lg:h-[260px] max-lg:w-[484px]"
              hidden={hideFeatureImage}
            />
            {showTicketSection && ticketBuyIntents.length > 0 && (
              <div className="mt-6">
                <TicketSection
                  creationMode={false}
                  headline="Tickets"
                  tickets={ticketBuyIntents}
                  slots={event.slots}
                  slotsSold={event.slotsSold}
                  amountChangeable={true}
                  onTicketAmountChange={handleChangeTicketAmount}
                >
                  <button
                    className="btn-primary ml-auto"
                    onClick={() => setHideCustomerInfo(!hideCustomerInfo)}
                    disabled={
                      ticketBuyIntents.filter((ticket) => ticket.amount > 0)
                        .length === 0
                    }
                  >
                    Jetzt buchen
                  </button>

                  <CustomerInfoForm
                    hidden={hideCustomerInfo}
                    onFormSubmit={handleSubmitToCheckout}
                  />
                </TicketSection>
              </div>
            )}
            <div className="mt-5">
              <h2 className="text-[22px] text-primaryDark max-sm:text-[20px]">
                Beschreibung
              </h2>

              <div
                className="mt-2 text-[20px] max-sm:gap-5 max-sm:text-[18px]"
                dangerouslySetInnerHTML={{
                  __html:
                    "<p>" +
                    event.descriptionLong.replaceAll("\n", "<br>") +
                    "</p>",
                }}
              ></div>
            </div>
          </>
        )}
        {showError && <EventNotFound />}
      </main>
    </div>
  );
};

function useEventCustomerPageLogic(): [
  string,
  boolean,
  boolean,
  Event | undefined,
  TicketBuyIntent[],
  boolean,
  (hide: boolean) => void,
  boolean,
  (hide: boolean) => void,
  (ticketId: string, step: number) => void,
  (customerInfo: CustomerInfo) => void
] {
  const [clientId, clientName] = useSetupClientOrRedirect();
  const navigate = useNavigate();
  const checkOutData = useContext(checkOutContext);
  const { eventId } = useParams();
  const [event, setEvent] = useState<Event>();
  const [ticketBuyIntents, setTicketBuyIntents] = useState<TicketBuyIntent[]>(
    []
  );
  const [hideFeatureImage, setHideFeatureImage] = useState(true);
  const [hideCustomerInfo, setHideCustomerInfo] = useState(true);
  const [showError, setShowError] = useState(false);
  const showTicketSection = event
    ? event.start.greaterThan(DateTime.now())
    : false;

  if (!eventId) {
    navigate(`${clientName}/not-found`);
  }

  useEffect(() => {
    async function fetchData() {
      const event = await getEvent(clientId, eventId!);
      if (event) {
        setEvent(event);
        setShowError(false);

        setTicketBuyIntents(
          event.options.map((ticket) => {
            return { ...ticket, amount: 0 };
          })
        );
      } else setShowError(true);
    }
    if (clientId && eventId) fetchData();
  }, [eventId, clientId]);

  const handleChangeTicketAmount = (ticketId: string, step: number) => {
    if (event && ticketBuyIntents) {
      const newTicketAmount = ticketBuyIntents.map((ticket) => ({ ...ticket }));
      const index = newTicketAmount.findIndex(
        (ticked) => ticked.id === ticketId
      );
      const newValue = newTicketAmount[index].amount + step;

      newTicketAmount[index].amount = newValue;

      if (newTicketAmount.filter((ticket) => ticket.amount > 0).length === 0) {
        setHideCustomerInfo(true);
      }

      if (
        newTicketAmount.reduce((sum, ticket) => sum + ticket.amount, 0) >
          event.slots - event.slotsSold &&
        event.slots !== EVENT_OPTION_INFINITE_SLOTS
      )
        return;

      if (newValue < 0) return;

      setTicketBuyIntents(newTicketAmount);
    }
  };

  const handleSubmitToCheckout = (customerInfo: CustomerInfo) => {
    if (event && ticketBuyIntents) {
      checkOutData!.setCheckOutData({
        eventId: event.id,
        eventTitle: event.title,
        tickets: ticketBuyIntents.filter((ticket) => ticket.amount > 0),
        customerInfo: customerInfo,
      });

      navigate(`/${clientName}/checkout`);
    }
  };

  return [
    clientName!,
    showError,
    showTicketSection,
    event,
    ticketBuyIntents,
    hideFeatureImage,
    setHideFeatureImage,
    hideCustomerInfo,
    setHideCustomerInfo,
    handleChangeTicketAmount,
    handleSubmitToCheckout,
  ];
}

export default EventCustomerPage;
